import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type OrderDispatchProps = {
  orderId?: string | null
}

const sendOrderDispatch = async ({ orderId }: OrderDispatchProps) => {
  const response = await api.post(
    `${import.meta.env.VITE_ORDER_DISPATCH_API_URL}/${orderId}`,
    {},
    {
      headers: {
        'x-api-key': import.meta.env.VITE_ORDER_DISPATCH_API_KEY,
      },
    },
  )
  return response.data
}

export const useOrderDispatchMutation = (OrderDispatchProps?: OrderDispatchProps) => {
  const orderId = OrderDispatchProps?.orderId

  return useMutation({
    mutationKey: ['dispatch', orderId],
    mutationFn: sendOrderDispatch,
    onSuccess: () => {
      triggerToast(
        true,
        'Solicitação de emissão feita com sucesso! Agora basta acompanhar o status da emissão.',
      )
    },
    onError: () => {
      triggerToast(false, 'Opss, ocorreu um erro ao enviar sua solicitação.')
    },
  })
}
