import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'

export function dependentAliceHealthSchema({
  insurerFields,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = insurerFields?.find((field) => field.name === 'maritalStatus')

  const gender = insurerFields?.find((field) => field.name === 'gender')

  const coparticipation = insurerFields?.find((field) => field.name === 'coparticipation')

  const gracePeriodReduction = insurerFields?.find((field) => field.name === 'gracePeriodReduction')

  const holderKinship = insurerFields?.find((field) => field.name === 'holderKinship')

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        isPartner: {
          type: 'boolean',
          title: 'Sócio/Cônjuge de sócio da empresa?',
          default: false,
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: maritalStatus?.title,
          oneOf:
            maritalStatus?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        holderKinship: {
          type: ['string', 'null'],
          title: holderKinship?.title,
          oneOf:
            holderKinship?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        mail: {
          type: ['string', 'null'],
          title: 'E-mail',
          format: 'email',
        },
        gender: {
          type: ['string', 'null'],
          title: gender?.title,
          oneOf:
            gender?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        gracePeriodReduction: {
          type: ['string', 'null'],
          title: gracePeriodReduction?.title,
          oneOf:
            gracePeriodReduction?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        healthPlan: {
          type: 'object',
          properties: {
            planName: {
              type: ['string', 'null'],
              title: 'Plano Médico*',
              oneOf: [{ title: '', const: '' }],
            },
            coparticipation: {
              type: ['string', 'null'],
              title: coparticipation?.title,
              oneOf:
                coparticipation?.options
                  .filter((o) => o.type === 'Dependent')
                  .map((option) => ({
                    title: option.intranetValue,
                    const: option.blissValue,
                  })) ?? [],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Saúde*',
            },
          },
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com',
        'ui:widget': 'TextWidget',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      gracePeriodReduction: {
        'ui:placeholder': 'Selecione a carência',
      },
      healthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        planName: {
          'ui:disabled': true,
          'ui:placeholder': 'Selecione o plano',
        },
        coparticipation: {
          'ui:placeholder': 'Selecione a coparticipação',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
    },
  }
}
