import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '..'

export function companyBradescoSchema({
  isOcrFilled,
  insurerFields,
}: GetSchemaProps): DynamicFormSchemaProps {
  const role = insurerFields?.find((field) => field.name === 'role')

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        document: {
          type: 'string',
          title: 'CNPJ*',
        },
        tradingName: {
          type: 'string',
          title: 'Razão social',
        },
        address: {
          type: 'object',
          properties: {
            zipCode: {
              type: 'string',
              title: 'CEP*',
            },
            entityAddress: {
              type: ['string', 'null'],
              title: 'Endereço*',
            },
            number: {
              type: 'string',
              title: 'Número*',
            },
            complement: {
              type: 'string',
              title: 'Complemento',
            },
            neighborhood: {
              type: 'string',
              title: 'Bairro',
            },
          },
        },
        externalLegalRepresentative: {
          type: 'object',
          properties: {
            legalRepresentativeSubtitle: {
              type: 'string',
              title: 'Representante Legal',
            },
            name: {
              type: 'string',
              title: 'Nome*',
            },
            role: {
              type: 'string',
              title: role?.title,
              oneOf:
                role?.options
                  .filter((o) => o.type === 'Company')
                  .map((option) => ({
                    title: option.intranetValue,
                    const: option.blissValue,
                  })) ?? [],
            },
            mail: {
              type: 'string',
              title: 'E-mail*',
              format: 'email',
            },
            phone: {
              type: 'string',
              title: 'Telefone*',
            },
            shouldCopyDataToContact: {
              type: 'boolean',
              title: 'Repetir para o contato da empresa?',
            },
          },
        },
        subtitle: {
          type: 'string',
          title: 'Contato da Empresa',
        },
        mail: {
          type: 'string',
          title: 'E-mail*',
          format: 'email',
        },
        phone: {
          type: 'string',
          title: 'Telefone*',
        },
      },
    },
    uiSchema: {
      document: {
        'ui:placeholder': '00.000.000/0000-00',
        'ui:options': {
          mask: 'cnpj',
        },
      },
      tradingName: {
        'ui:placeholder': 'Ex.: Espaço de Beleza LTDA',
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
      address: {
        'ui:options': {
          colSpan: 2,
        },
        zipCode: {
          'ui:placeholder': '00000-000',
          'ui:options': {
            mask: 'cep',
          },
          'ui:widget': 'OCRCnpjTextInputWidget',
        },
        entityAddress: {
          'ui:placeholder': 'Buscar endereço',
          'ui:widget': 'AutoCompleteWidget',
        },
        number: {
          'ui:placeholder': '000',
          'ui:widget': 'OCRCnpjTextInputWidget',
        },
        complement: {
          'ui:placeholder': 'Ex.: Apto 123',
          'ui:widget': 'OCRCnpjTextInputWidget',
        },
        neighborhood: {
          'ui:placeholder': 'Ex.: Pinheiros',
        },
      },
      subtitle: {
        'ui:widget': 'SubtitleWidget',
      },
      externalLegalRepresentative: {
        'ui:options': {
          colSpan: 2,
        },
        legalRepresentativeSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        name: {
          'ui:placeholder': 'Ex.: Maria Silva',
          'ui:disabled': isOcrFilled,
          'ui:widget': 'OCRDisabledTextInputWidget',
        },
        role: {
          'ui:placeholder': 'Selecione o cargo',
        },
        mail: {
          'ui:placeholder': 'exemplo@email.com.br',
          'ui:widget': 'TextWidget',
        },
        phone: {
          'ui:placeholder': '(00) 0 0000-0000',
          'ui:options': {
            mask: 'phone',
          },
        },
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com.br',
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
    },
  }
}
