/* eslint-disable react/prop-types */
import { useEffect } from 'react'

import { useVariant } from '@unleash/proxy-client-react'
import { Button, Toast } from 'design-system/components'
import Exclamation from 'design-system/exclamation'

import { useOrderDispatchMutation } from '@/App/clients/healthPlansOrders/mutations/orderDispatch'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useOrderDispatchMutation as useNewOrderDispatchMutation } from '@/App/clients/oderDispatch/mutations/orderDispatch'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import { ButtonDiv, CancelButton, ModalContent, ModalSubTitle, ModalTitle } from './style'

interface OrderDispatchingProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string | null
  insurer?: string | null
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
  reloadOrderData?: () => void
}

export const OrderDispatchingModal: React.FC<OrderDispatchingProps> = ({
  setIsModalOpen,
  setOrderStatus,
  orderId,
  insurer,
  reloadOrderData,
}) => {
  const insurerEdge = useVariant('bliss-intranet-insurer-edge')?.payload?.value
  const insurerEdgeFlags = JSON.parse(insurerEdge ?? '{}')
  const isDispatchEnabled = insurerEdgeFlags['dispatch']

  const allowedNewOrderDispatchInsurers = ['Porto Seguro']
  const isNewOrderDispatchAllowed =
    insurer && isDispatchEnabled && allowedNewOrderDispatchInsurers.includes(insurer)

  const sendDispatchOrder = useOrderDispatchMutation()
  const sendNewDispatchOrder = useNewOrderDispatchMutation()

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  useEffect(() => {
    if (sendDispatchOrder.isSuccess || sendNewDispatchOrder.isSuccess) {
      const currentStatus = 'DISPATCH_IN_PROGRESS'

      if (orderInformationQuery?.data?.status !== currentStatus) {
        updateStatusMutation.mutateAsync({
          nextStatus: currentStatus,
          order: orderInformationQuery?.data,
        })
        setOrderStatus && setOrderStatus(currentStatus)
      }

      setIsModalOpen && setIsModalOpen(false)
    }
  }, [sendDispatchOrder.isSuccess || sendNewDispatchOrder.isSuccess])

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      reloadOrderData && reloadOrderData()
    }
  }, [updateStatusMutation.isSuccess])

  return (
    <ModalContent>
      <Exclamation />
      <ModalTitle>Tem certeza que deseja solicitar Emissão Automática?</ModalTitle>
      <ModalSubTitle>
        Ao confirmar, o robô da Bliss automaticamente cadastrará esta proposta no site da operadora.
        Certifique-se de que todos os campos obrigatórios estão preenchidos corretamente.
      </ModalSubTitle>

      <ButtonDiv>
        <Button
          loading={sendDispatchOrder.isPending || sendNewDispatchOrder.isPending}
          onClick={() => {
            const dispatchData = { orderId, insurer }
            const newDispatchData = { orderId }
            isNewOrderDispatchAllowed
              ? sendNewDispatchOrder.mutate(newDispatchData)
              : sendDispatchOrder.mutate(dispatchData)
          }}
        >
          Solicitar emissão automática
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
